import React from 'react';
import './../social/social.scss';
const LinkedIn = ({ profile }) => {
    const url = `https://uk.linkedin.com/company/${profile}/`
    return (
        <span className="social">
            <a href={url} target="_blank" rel="noopener noreferrer">
                <img src="https://static.licdn.com/scds/common/u/img/webpromo/btn_in_20x15.png" alt="Hovergen" />
            </a>
        </span>);
}

export default LinkedIn;