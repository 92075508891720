import React from 'react';
import './../banner/banner.scss';
import { UncontrolledCarousel } from 'reactstrap';

const items = [
    {
        src: './assets/websol.png',
        altText: 'Digital solutions',
        caption: ''
    },
    {
        src: './assets/inno.png',
        altText: 'Innovation',
        caption: ''
    },
    {
        src: './assets/tools.png',
        altText: 'Tools',
        caption: ''
    }
];

const Banner = ({ hasControls, hasAutoPlay }) => {
    const controls = hasControls != null ? hasControls : true;
    const autoPlay = hasAutoPlay != null ? hasAutoPlay : true;
    return (
        <div className="banner">
            <UncontrolledCarousel items={items} controls={controls} autoPlay={autoPlay} />
        </div>
    );
}
export default Banner;