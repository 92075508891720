import React, { Component } from 'react';
import './../home/home.scss';
import Banner from './../banner/Banner';
import CardUI from './../card/Card'
const implementations = [
    { name: 'Business applications development and implementation' },
    { name: 'Native application development and implementation' },
    { name: 'MI Reporting implementation' },
    { name: 'Responsive UX mobile and web applications' },
    { name: 'Content management solutions implementation' },
    { name: 'Data migration and integration solutions' },
];
const roadmap = [
    { name: 'Cloud solutions on Microsoft Azure and Google, Amazon and IBM platform' },
    { name: 'AI, Robotics and Data science solutions' },
    { name: 'Hybrid solution via different tools and technoligies' },
    { name: 'IaaS, PaaS and SaaS digital solutions' },
    { name: 'Web and Native mobile apps for Android/iOS devices' },
    { name: 'BI and Data Mining solution implementations' },
];
const clients = [
    { name: 'Miller Insurance' },
    { name: 'Llyods Register' },
    { name: 'Sogeti UK' },
    { name: 'London Borough Croydon' },
    { name: 'Shell International' },
];
export class Home extends Component {
    render() {
        return (
            <div className="main-section">
                <Banner hasControls={false} hasAutoPlay={true} />
                <label>About us</label>
                <section className="home">
                    <div className="row">
                        <div className="col">
                            <CardUI title="Our implementations" items={implementations} />
                        </div>
                        <div className="col">
                            <CardUI title="Road map" items={roadmap} />
                        </div>
                        <div className="col">
                            <CardUI title="Our Clients" items={clients} />
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
