import React from 'react';
import './../social/social.scss';
const Twitter = ({ profile, displayCount, displayName }) => {
    const showCount = displayCount ? displayCount : false;
    const showName = displayName ? displayName : false;
    return (
        <span className="social">
            <a href="https://twitter.com/hovergen?ref_src=twsrc%5Etfw"
                className="twitter-follow-button"
                data-show-count={showCount}
                data-show-screen-name={showName}>@{profile}</a>
        </span>);
}

export default Twitter;