import React, { Component } from 'react';
import './../service/service.scss';
import Banner from './../banner/Banner';
import CardUI from './../card/Card'
const iaas = [
    { name: 'We have team to experts to setup infracture in Cloud' },
    { name: 'Setting up Virtual network in Cloud' },
    { name: 'Cloud Firewall configurations' },
    { name: 'Identy server integration and configuration' },
    { name: 'Securty policy configuration in cloud' },
];
const saas = [
    { name: 'Custom applicatiaon development' },
    { name: 'Software solutions and Consultancy' },
    { name: 'Data migration services' },
    { name: 'Application support' },
    { name: 'Application Testing' },
];
const paas = [
    { name: 'We provice Quote binding platform for our clients' },
    { name: 'We provide Document review platform for our clients' },
];
export class Service extends Component {
    render() {
        return (
            <div className="main-section">
                <Banner hasControls={false} hasAutoPlay={true} />
                <label>Our Services</label>
                <section className="service">
                    <div className="row">
                        <div className="col">
                            <CardUI title="Infrasture as a Service (IaaS)" items={iaas} />
                        </div>
                        <div className="col">
                            <CardUI title="Software as a Service (SaaS)" items={saas} />
                        </div>
                        <div className="col">
                            <CardUI title="Platform as a Service (SaaS)" items={paas} />
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
