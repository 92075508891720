import React from 'react';
import './../card/card.scss';
const CardUI = ({ title, items }) => {
    return (
        <div className="card-ui">
            <div className="card">
                <div className="card-body">
                    <label className="card-title">{title != null ? title : ''}</label>
                    <ul className="list-group">
                        {items.map((item, index) => <li key={index}>{item.name}</li>)}
                    </ul>
                </div>
            </div>
        </div>);
}

export default CardUI;