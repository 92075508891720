import React, { Component } from 'react';
import './../contactus/contactus.scss';
import Banner from './../banner/Banner';
export class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            address: {
                line1: '27 Hill View Driver',
                line2: '',
                city: 'Welling',
                postcode: 'DA16 3RS',
                country: 'United Kingdom',
                phone: '+44 (0) 20 8319 8629'
            },
            email: {
                contactEmail: 'contact@hovergen.com',
                supportEmail: 'support@hovergen.com'
            },
            registration: {
                name: 'Hovergen Limited',
                number: '09201693',
                vat: '194 9223 76'
            }
        };
    }
    render() {
        return (
            <div className="main-section">
                <Banner hasControls={false} hasAutoPlay={true} />
                <label>Contact us</label>
                <section className="contact-us">
                    <div className="card">
                        <div className="card-body">
                            <div className="card-title">Address</div>
                            <p>
                                {this.state.address.line1}
                                <br />
                                {this.state.address.city}
                                <br />
                                {this.state.address.postcode}
                                <br />
                                <span>{this.state.address.country}</span>
                                <br />
                                <span>Phone: </span>{this.state.address.phone}
                            </p>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="card-title">Email us</div>
                            <div>
                                <label>Contact: </label>{this.state.email.contactEmail}
                            </div>
                            <div>
                                <label>Support: </label>{this.state.email.supportEmail}
                            </div>
                            <p>
                                Our office open's Monday to Friday between 9:00 AM to 5:00 PM (BST), except public/bank holidays.
                                Please allow us 48 hours to respond to your queries.
                            </p>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="card-title">Registration details</div>
                            <div>
                                <label>Registered Name: </label>{this.state.registration.name}
                            </div>
                            <div>
                                <label>Company Number: </label>{this.state.registration.number}
                            </div>
                            <div>
                                <label>VAT Number: </label>{this.state.registration.vat}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
