import React from 'react';
import LinkedIn from './../social/LinkedIn'
import Twitter from './../social/Twitter'
import './../footer/footer.scss';
const Footer = ({ name }) => {
    const year = new Date().getFullYear();
    const profile = "Hovergen";
    return (
        <footer className="fixed-bottom footer-ui">
            <section>
                <p>
                    <LinkedIn profile={profile} />
                    <Twitter profile={profile} displayCount="false" displayName="false" />
                    <span> © 2014-{year} Copyright : {name}</span>
                </p>
            </section>
        </footer>);
}
export default Footer;
