import React, { Component } from 'react';
import { Button } from 'reactstrap';
import './../career/career.scss';
import Banner from './../banner/Banner';
import CardUI from './../card/Card'
import { getProfile } from '../../services/ProfileService'

const clientTools = [
    { name: 'Angular 8+' },
    { name: 'ReactJS 16+' },
    { name: 'ReactNative 0.6+' },
    { name: 'Bootstraps 4+ / Material 8+' },
    { name: 'TypeScript' },
    { name: 'NodeJS 10+' },
    { name: 'Javascript ES6+' },
    { name: 'HTML5, SCSS, CSS' },
];
const serverTools = [
    { name: 'C#/F#' },
    { name: 'C++' },
    { name: 'NodeJS' },
    { name: 'Docker/Kubernetes' },
    { name: 'Python 3+' }
];
const databases = [
    { name: 'Microsoft SQL Server' },
    { name: 'Azure SQL/Cosmos DB' },
    { name: 'Postgres' },
    { name: 'Mongo DB' },
    { name: 'MySQL' },
    { name: 'Oracle' }
];
const frameworks = [
    { name: 'MVC' },
    { name: 'MVVM' },
    { name: 'Entity Frameworks, Mongoos, Dapper' },
    { name: '.Net/.Net Core' },
    { name: 'SpecFlow' },
    { name: 'Django/Flask' }
];
export class Career extends Component {
    componentDidMount() {
        console.info('Career Mounted!');
        getProfile();
    }
    render() {
        return (
            <div className="main-section">
                <Banner hasControls={false} hasAutoPlay={true} />
                <label>Digital Tools</label>
                <section className="carrer">
                    <div className="row">
                        <div className="col">
                            <CardUI title="Client Tools" items={clientTools} />
                        </div>
                        <div className="col">
                            <CardUI title="Server Tools" items={serverTools} />
                        </div>
                        <div className="col">
                            <CardUI title="Databases" items={databases} />
                        </div>
                        <div className="col">
                            <CardUI title="Frameworks" items={frameworks} />
                        </div>
                    </div>
                </section>
                <div className="upload-section">
                    <div className="row">
                        <div className="col"><Button block className="btn btn-primary" >Upload CV</Button></div>
                    </div>                   
                </div>
            </div>
        );
    }
}
