import axios from 'axios'
export const getProfile = () => {
    console.log('GET Profile!');
    axios.get('/profile')
        .then(result => result.data)
        .then(data => {
            console.log(data)
            return data;
        })
        .catch(err => console.log(err))
}