import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/home/Home';
import { Service } from './components/service/Service';
import { Career } from './components/career/Career';
import { ContactUs } from './components/contactus/ContactUs';
import './main.scss'

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Route exact path='/' component={Home} />
                <Route path='/services' component={Service} />
                <Route path='/career' component={Career} />
                <Route path='/contact' component={ContactUs} />
            </Layout>
        );
    }
}
